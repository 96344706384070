import {Navigate, Route, Routes} from 'react-router-dom';
import ConsolePage from "./pages/ConsolePage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from './pages/LogoutPage';
import { CssBaseline, CssVarsProvider, extendTheme } from '@mui/joy';


const theme = extendTheme({
  "colorSchemes": {
    "light": {
      "palette": {
        "primary": {
          "50": "#fffbeb",
          "100": "#fef3c7",
          "200": "#fde68a",
          "300": "#fcd34d",
          "400": "#fbbf24",
          "500": "#f59e0b",
          "600": "#d97706",
          "700": "#b45309",
          "800": "#92400e",
          "900": "#78350f"
        }
      }
    },
    "dark": {
      "palette": {}
    }
  }
})


export default function App() {
  return (

    <CssVarsProvider 
      disableTransitionOnChange
      theme={theme}
    >
      <CssBaseline/>

      <Routes>
        <Route path='/' element={<Navigate to={'/console'} />}/>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/logout" element={<LogoutPage/>}/>
        <Route path="/console/*" element={<ConsolePage/>}/>
      </Routes>
    </CssVarsProvider>
  );
}