/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment, useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { apiClient } from '../../../../lib/api/api-client';
import dayjs from 'dayjs';


function RowMenu() {
  return (
    <Dropdown>
      <MenuButton
        slots={{root: IconButton}}
        slotProps={{root: {variant: 'plain', color: 'neutral', size: 'sm'}}}
      >
        <MoreHorizRoundedIcon/>
      </MenuButton>
      <Menu size="sm" sx={{minWidth: 140}}>
        {/*<MenuItem>Edit</MenuItem>*/}
        {/*<MenuItem>Rename</MenuItem>*/}
        {/*<MenuItem>Move</MenuItem>*/}
        <MenuItem disabled>액션 없음</MenuItem>
        {/*<Divider />*/}
        {/*<MenuItem color="danger">Delete</MenuItem>*/}
      </Menu>
    </Dropdown>
  );
}

type RowType = {
  id: number;
  createdAt?: string;
  username: string;
}


export default function AdminUserTable() {
  const [rows, setRows] = useState<RowType[]>([])
  const [page, setPage] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [last, setLast] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    apiClient().GET("/admin/user", {
      params: {
        query: {
          page: page,
          size: 10
        }
      }
    }).then(({data}) => {
      setRows(data!.content as RowType[])
      setTotalPage(data!.totalPages!)
      setLast(data!.last === true)
      setLoading(false)
    })

  }, [page])

  return (
    <Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
          <tr>
            <th style={{width: 40, textAlign: 'center', padding: '12px 6px'}}>
              ID
            </th>
            <th style={{width: 140, padding: '12px 6px'}}>사용자명</th>
            <th style={{width: 140, padding: '12px 6px'}}>생성일시</th>
            <th style={{width: 50, padding: '12px 6px'}}></th>
          </tr>
          </thead>
          <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>
                <Typography level="body-sm" style={{textAlign: 'center'}}>{row.id}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.username}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{dayjs(new Date(row.createdAt!).toLocaleString()).format('YYYY-MM-DD HH:mm:ss')}</Typography>
              </td>
              <td>
                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                  <RowMenu/>
                </Box>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'},
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon/>}
          disabled={loading || page === 0}
          onClick={() => {
            setLoading(true);
            setPage(page - 1);
          }}
        >
          이전 페이지
        </Button>

        <Box sx={{flex: 1}}/>
        <Box fontSize="small">
          {page + 1} / {totalPage}
        </Box>
        


        {/*{['2', '3', '4'].map((page) => (*/}
        {/*  <IconButton*/}
        {/*    key={page}*/}
        {/*    size="sm"*/}
        {/*    variant={Number(page) ? 'outlined' : 'plain'}*/}
        {/*    color="neutral"*/}
        {/*  >*/}
        {/*    {page}*/}
        {/*  </IconButton>*/}
        {/*))}*/}

        <Box sx={{flex: 1}}/>

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon/>}
          disabled={loading || last}
          onClick={() => {
            setLoading(true);
            setPage(page + 1);
          }}
        >
          다음 페이지
        </Button>
      </Box>
    </Fragment>
  );
}
