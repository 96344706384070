import {useNavigate} from "react-router-dom";
import { useEffect } from 'react';

export default function LogoutPage() {
  const navigate = useNavigate()
  useEffect(() => {
    localStorage.removeItem('token')
    navigate('/login')
  }, [navigate])
  
  return <></>;
}