import {CssVarsProvider, extendTheme} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import {Route, Routes, useNavigate} from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import PartnerPage from "./console/partner/PartnerPage";

import AdminUserPage from "./console/admin/user/AdminUserPage";
import ConsoleHomePage from "./console/home/ConsoleHomePage";

import AdminUserCreatePage from './console/admin/user/AdminUserCreatePage';
import { useEffect } from 'react';
import { apiClient } from '../lib/api/api-client';
import SimpleFormSetPage from './console/simple-form/set/SimpleFormSetPage';
import SimpleFormSetCreatePage from './console/simple-form/set/SimpleFormSetCreatePage';


export default function ConsolePage() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (!token) {
      navigate('/login')

    } else {
      apiClient().POST(
        '/admin/auth/login/token/verify',
        {
          body: {
            token: token!
          }
        }
      ).then((resp) => {
        if (resp.error) {
          navigate('/logout')
        }
      })
    }

  
  }, [navigate, token])
  
  return (
    <Box sx={{display: 'flex', minHeight: '100dvh'}}>
      <Header/>
      <Sidebar/>
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: {xs: 2, md: 6},
          pt: {
            xs: 'calc(12px + var(--Header-height))',
            sm: 'calc(12px + var(--Header-height))',
            md: 3,
          },
          pb: {xs: 2, sm: 2, md: 3},
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          height: '100dvh',
          gap: 1,
        }}
      >
        <Routes>
          {/*<Route path="/" element={<Navigate replace to="/console/partner"/>}/>*/}
          <Route path="/" element={<ConsoleHomePage/>}/>
          <Route path="/partner" element={<PartnerPage/>}/>
          <Route path="/admin/user" element={<AdminUserPage/>}/>
          <Route path="/admin/user/create" element={<AdminUserCreatePage/>}/>
          <Route path="/simple-form/set" element={<SimpleFormSetPage/>}/>
          <Route path="/simple-form/set/create" element={<SimpleFormSetCreatePage/>}/>
        </Routes>
      </Box>
    </Box>
  );
}