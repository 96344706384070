import Typography from "@mui/joy/Typography";

import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import {Link as RouterLink} from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AddRoundedIcon from "@mui/icons-material/Add";
import { Button } from "@mui/joy";
import SimpleFormSetTable from "./SimpleFormSetTable";

export default function SimpleFormSetPage() {
  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small"/>}
          sx={{pl: 0}}
        >
          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console"
            aria-label="Home"
          >
            <HomeRoundedIcon/>
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            간편문진
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            기본 문진표 관리
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: {xs: 'column', sm: 'row'},
          alignItems: {xs: 'start', sm: 'center'},
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >

        <Typography level="h2" component="h1">
          기본 문진표 관리
        </Typography>


        <Link
          underline="none"
          color="neutral"
          component={RouterLink}
          to="/console/simple-form/set/create"
        >
          <Button
            color="primary"
            startDecorator={<AddRoundedIcon/>}
            size="sm"
          >
            문진표 생성
          </Button>
        </Link>
      </Box>

      <SimpleFormSetTable />
    </>
  );
}