import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import AddRoundedIcon from "@mui/icons-material/Add";
import PartnerTable from "./PartnerTable";
import * as React from "react";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import {Link as RouterLink} from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

export default function PartnerPage() {
  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small"/>}
          sx={{pl: 0}}
        >
          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console"
            aria-label="Home"
          >
            <HomeRoundedIcon/>
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            파트너
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            파트너 관리
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: {xs: 'column', sm: 'row'},
          alignItems: {xs: 'start', sm: 'center'},
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >

        <Typography level="h2" component="h1">
          파트너 관리
        </Typography>

      </Box>
      <PartnerTable/>
    </>
  );
}