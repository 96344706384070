/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment, useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { apiClient } from '../../../../lib/api/api-client';
import { Input } from '@mui/joy';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';


type RowType = {
  id: number
  createdAt?: string
  setName: string
}


export default function SimpleFormSetTable() {
  const [rows, setRows] = useState<RowType[]>([])

  const [totalPage, setTotalPage] = useState(0)
  const [last, setLast] = useState(true)
  const [loading, setLoading] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const client = apiClient()

  const getPage = () => {
    return parseInt(searchParams.get('page') || '1')
  }

  const updatePage = () => {
    client.GET("/admin/simple-form/question/set", {
      params: {
        query: {
          page: getPage() - 1,
          size: 10
        }
      }
    }).then(({data}) => {
      setRows(data!.content as RowType[])
      setTotalPage(data!.totalPages!)
      setLast(data!.last === true)
      setLoading(false)
    })
  }

  useEffect(() => {
    updatePage()
  }, [searchParams])

  return (
    <Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
          <tr>
            <th style={{width: 40, textAlign: 'center', padding: '12px 6px'}}>
              ID
            </th>
            <th style={{width: 140, padding: '12px 6px'}}>설문지명</th>
            <th style={{width: 140, padding: '12px 6px'}}>생성일</th>
            <th style={{width: 50, padding: '12px 6px'}}></th>
          </tr>
          </thead>
          <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>
                <Typography level="body-sm" style={{textAlign: 'center'}}>{row.id}</Typography>
              </td>
              
              <td>
                <Typography level="body-sm">{row.setName}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{dayjs(new Date(row.createdAt!).toLocaleString()).format('YYYY-MM-DD')}</Typography>
              </td>
              <td>
                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                  <Dropdown>
                    <MenuButton
                      slots={{root: IconButton}}
                      slotProps={{root: {variant: 'plain', color: 'neutral', size: 'sm'}}}
                    >
                      <MoreHorizRoundedIcon/>
                    </MenuButton>
                    <Menu size="sm" sx={{minWidth: 140}}>
                      <MenuItem
                        onClick={async () => {
                          if (!window.confirm(`[${row.setName}] 삭제하시겠습니까?`)) return

                          const resp = await client.DELETE(
                            '/admin/simple-form/question/set/{id}',
                            {
                              params: {
                                path: {
                                  id: row.id
                                }
                              }
                            }
                          )

                          if (resp.error) {
                            alert('삭제 실패')
                            return
                          }
                          
                          alert('삭제 성공')
                          updatePage()
                        }}
                      >
                        삭제
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </Box>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'},
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon/>}
          disabled={loading || getPage() === 1}
          onClick={() => {
            setLoading(true);
            setSearchParams({...searchParams, page: (getPage() - 1).toString()})
          }}
        >
          이전 페이지
        </Button>

        <Box sx={{flex: 1}}/>
        <Box fontSize="small">
          {getPage()} / {totalPage}
        </Box>
        

        <Box sx={{flex: 1}}/>

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon/>}
          disabled={loading || last}
          onClick={() => {
            setLoading(true);
            setSearchParams({...searchParams, page: (getPage() + 1).toString()})
          }}
        >
          다음 페이지
        </Button>
      </Box>
    </Fragment>
  );
}
