import {useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import { apiClient } from '../../../../lib/api/api-client';
import { Checkbox, List, ListItem } from '@mui/joy';

type QuestionItemType = {
  uid: string
  question: string
  subQuestion: boolean
  beforeUid: string
  itemGroupId: string
  itemGroupName: string
}

export default function SimpleFormSetCreatePage() {
  const [loading, setLoading] = useState(false)
  const [questionItems, setQuestionItems] = useState<Array<QuestionItemType>>([])
  const [selectedItems, setSelectedItems] = useState<Array<string>>([])
  const [questionSetName, setQuestionSetName] = useState<string>("")

  const navigate = useNavigate()

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true)

    apiClient().POST(
      "/admin/simple-form/question/set", {
        body: {
          setName: questionSetName,
          formQuestionItemUids: selectedItems.sort()
        }
      }
    ).then((res) => {
      if (res.error) {
        alert('저장 실패')
        setLoading(false)
      } else {
        navigate("/console/simple-form/set")
      }
    })
  }


  useEffect(() => {
    apiClient().GET(
      '/admin/simple-form/question/item'
    ).then((res) => {
      let items = res.data?.map((item) => {
        return item as QuestionItemType
      })
      setQuestionItems(items || [])
    })
  }, [])

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small"/>}
          sx={{pl: 0}}
        >
          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console"
          >
            <HomeRoundedIcon/>
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            설문
          </Typography>

          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console/form/set"
          >
            <Typography fontWeight={500} fontSize={12}>
              설문지 관리
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            설문지 생성
          </Typography>
        </Breadcrumbs>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: {xs: 'column', sm: 'row'},
            alignItems: {xs: 'start', sm: 'center'},
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >

          <Typography level="h2" component="h1">
            설문지 생성
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="neutral"
              size="sm"
              disabled={loading}
              sx={{
                marginRight: "10px"
              }}
              onClick={() => {
                navigate(-1)
              }}
            >
              돌아가기
            </Button>

            <Button
              color="primary"
              size="sm"
              disabled={loading}
              type='submit'
            >
              저장하기
            </Button>
          </Box>

        </Box>

        <Box mb={5}>
          <Typography id="form-item-group" level="body-lg" fontWeight="lg" mb={1}>
            설문지 명
          </Typography>
          <Input
            value={questionSetName}
            onChange={e => setQuestionSetName(e.target.value)}
          />
        </Box>

        <Box>
        <Typography id="form-item-group" level="body-lg" fontWeight="lg" mb={3}>
          설문지 항목 선택
        </Typography>

        {Array.from(new Set(questionItems.map(item => {return item.itemGroupName}))).map(groupName => {
          return <Box mb={5}>
            <Typography level='body-md' fontWeight="lg" mb={2}>{groupName}</Typography>

            {questionItems.filter(item => item.itemGroupName === groupName).map((item) => {
              return <Box mb={0.5}>
                <Checkbox 
                  name={item.uid}
                  label={`[${item.uid}] ${item.question}`}
                  checked={selectedItems.indexOf(item.uid) !== -1}
                  disabled={item.subQuestion}
                  onClick={(e) => {
                    if (item.subQuestion) return

                    // 상위 질문 선택시 하위 질문도 함께 선택한다.
                    const subs = questionItems.filter(el => el.beforeUid === item.uid).map(el => el.uid)

                    if (selectedItems.indexOf(item.uid) !== -1) {
                      setSelectedItems(selectedItems.filter(el => el !== item.uid && subs.indexOf(el) === -1))
                    } else {
                      setSelectedItems([...selectedItems, ...subs, item.uid])
                    }
                  }}
                />
              </Box>
            })}
          </Box>
        })}

        </Box>
      </form>
    </>
  );
}