import * as React from "react";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import {Link as RouterLink} from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

export default function ConsoleHomePage() {
  return (
    <>

      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small"/>}
          sx={{pl: 0}}
        >
          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console"
          >
            <HomeRoundedIcon/>
          </Link>
        </Breadcrumbs>
      </Box>


      <div>Hello!</div>
    </>
  );
}