import {useState} from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import { apiClient } from '../../../../lib/api/api-client';


export default function AdminUserCreatePage() {
  const [loading, setLoading] = useState(false);
  const [formItems, setFormItems] = useState<{
    username?: string;
    password?: string;
  }>({});
  const navigate = useNavigate()

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setLoading(true)
    apiClient().POST(
      "/admin/user", {
        body: {
          username: formItems.username!,
          password: formItems.password!
        }
      }
    ).then(() => {
      navigate("/console/admin/user")
    })
  }

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small"/>}
          sx={{pl: 0}}
        >
          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console"
          >
            <HomeRoundedIcon/>
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            파트너
          </Typography>

          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console/partner/user"
          >
            <Typography fontWeight={500} fontSize={12}>
              어드민 사용자 관리
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            어드민 사용자 생성
          </Typography>
        </Breadcrumbs>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: {xs: 'column', sm: 'row'},
            alignItems: {xs: 'start', sm: 'center'},
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >

          <Typography level="h2" component="h1">
            어드민 사용자 생성
          </Typography>
          <Box>
            <Button
              variant="outlined"
              color="neutral"
              size="sm"
              disabled={loading}
              sx={{
                marginRight: "10px"
              }}
              onClick={() => {
                navigate(-1)
              }}
            >
              돌아가기
            </Button>

            <Button
              color="primary"
              size="sm"
              disabled={loading}
              type='submit'
            >
              저장하기
            </Button>
          </Box>

        </Box>

        <Card>

          <Stack
            direction="row"
            spacing={3}
          >
            <Stack spacing={2} style={{flexGrow: 1}}>
              <FormLabel>사용자명</FormLabel>
              <FormControl>
                <Input 
                  placeholder="username" 
                  required
                  onChange={(event) => {
                  setFormItems({...formItems, username: event.target.value})
                }}/>
              </FormControl>
            </Stack>
          </Stack>
          
          <Stack
            direction="row"
            spacing={3}
          >
            <Stack spacing={2} style={{flexGrow: 1}}>
              <FormLabel>패스워드</FormLabel>
              <FormControl>
                <Input 
                  placeholder="password" 
                  type='password'
                  required
                  onChange={(event) => {
                  setFormItems({...formItems, password: event.target.value})
                }}/>
              </FormControl>
            </Stack>
          </Stack>
          
        </Card>
      </form>
    </>
  );
}