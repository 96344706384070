/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment, useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { apiClient } from '../../../lib/api/api-client';
import { Link } from '@mui/joy';
import dayjs from 'dayjs';


type RowType = {
  id: number
  createdAt?: string
  username: string
  verified: boolean
  realName: string
  phone: string
  email: string
  hospitalName: string
  hospitalOwner: boolean
  hospitalLogoImageUrl?: string
  documentImageUrl?: string
}


export default function PartnerTable() {
  const [rows, setRows] = useState<RowType[]>([])
  const [page, setPage] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [last, setLast] = useState(true)
  const [loading, setLoading] = useState(false)

  const client = apiClient()

  const refreshTable = () => {
    client.GET("/admin/partner", {
      params: {
        query: {
          page: page,
          size: 10
        }
      }
    }).then(({data}) => {
      setRows(data!.content as RowType[])
      setTotalPage(data!.totalPages!)
      setLast(data!.last === true)
      setLoading(false)
    })

  }

  useEffect(() => {
    refreshTable()
  }, [page])

  return (
    <Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
          <tr>
            <th style={{width: 60, textAlign: 'center', padding: '12px 6px'}}>
              ID
            </th>
            <th style={{width: 140, padding: '12px 6px'}}>Username</th>
            <th style={{width: 100, padding: '12px 6px'}}>이름</th>
            <th style={{width: 140, padding: '12px 6px'}}>병원명</th>
            <th style={{width: 140, padding: '12px 6px'}}>휴대전화</th>
            <th style={{width: 200, padding: '12px 6px'}}>이메일</th>
            <th style={{width: 40, padding: '12px 6px'}}>원장</th>
            <th style={{width: 40, padding: '12px 6px'}}>승인</th>
            <th style={{width: 60, padding: '12px 6px'}}>증빙</th>
            <th style={{width: 80, padding: '12px 6px'}}>병원로고</th>
            <th style={{width: 160, padding: '12px 6px'}}>신청일시</th>
            <th style={{width: 70, padding: '12px 6px'}}></th>
          </tr>
          </thead>
          <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>
                <Typography level="body-sm" sx={{textAlign: 'center'}}>{row.id}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.username}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.realName}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.hospitalName}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.phone}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.email}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.hospitalOwner && 'O'}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.verified && 'O'}</Typography>
              </td>

              <td>
                <Typography level="body-sm">
                  <Link href={row.documentImageUrl} target='_blank'>열기</Link>
                </Typography>
              </td>

              <td>
                {row.hospitalLogoImageUrl && 
                  <Typography level="body-sm">
                    <Link href={row.hospitalLogoImageUrl} target='_blank'>열기</Link>
                  </Typography>
                }
              </td>

              <td>
                <Typography level="body-sm">{dayjs(new Date(row.createdAt!).toLocaleString()).format('YYYY-MM-DD HH:mm:ss')}</Typography>
              </td>

              <td>
                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                  <Dropdown>
                    <MenuButton
                      slots={{root: IconButton}}
                      slotProps={{root: {variant: 'plain', color: 'neutral', size: 'sm'}}}
                    >
                      <MoreHorizRoundedIcon/>
                    </MenuButton>
                    <Menu size="sm" sx={{minWidth: 140}}>
                      <MenuItem onClick={async _ => {
                        const resp = await client.PUT(
                          '/admin/partner/{partnerId}/verify',
                          {
                            params: {
                              path: {
                                partnerId: row.id
                              }
                            },
                            body: {
                              verified: true
                            }
                          }
                        )
                        if (!resp.error) {
                          alert('가입 승인 성공')
                          refreshTable()
                        } else {
                          alert('가입 승인 실패')
                        }
                        
                      }}>
                        가입 승인
                      </MenuItem>

                      <MenuItem onClick={async _ => {
                        const resp = await client.PUT(
                          '/admin/partner/{partnerId}/verify',
                          {
                            params: {
                              path: {
                                partnerId: row.id
                              }
                            },
                            body: {
                              verified: false
                            }
                          }
                        )
                        if (!resp.error) {
                          alert('가입 승인 취소 성공')
                          refreshTable()
                        } else {
                          alert('가입 승인 취소 실패')
                        }
                        
                      }}>
                        가입 승인 취소
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </Box>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'},
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon/>}
          disabled={loading || page === 0}
          onClick={() => {
            setLoading(true);
            setPage(page - 1);
          }}
        >
          이전 페이지
        </Button>

        <Box sx={{flex: 1}}/>
        <Box fontSize="small">
          {page + 1} / {totalPage}
        </Box>
        

        <Box sx={{flex: 1}}/>

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon/>}
          disabled={loading || last}
          onClick={() => {
            setLoading(true);
            setPage(page + 1);
          }}
        >
          다음 페이지
        </Button>
      </Box>
    </Fragment>
  );
}
